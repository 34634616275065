export const projects = [
	{
		title: "7 Arts Real Estate",
		subtitle: "MongoDB, Express, React, Node",
		description: "A real estate broker dashboard.",
		image: "./sevenArts.gif",
		link: "https://github.com/cfonseca24/sevenArtsRealEstate",
	},
	{
		title: "Roman Fuller Personal/Business Website",
		subtitle: "JavaScript, HTML, CSS",
		description: "Created a personal/business website for client.",
		image: "./roman.gif",
		link: "https://romanfuller.netlify.app/",
	},
	{
		title: "SwingBeat Landing Page",
		subtitle: "Bootstrap, CSS, JavaScript",
		description: "Entertainment Company Landing Page.",
		image: "./sbwalkthrough.gif",
		link: "https://github.com/cfonseca2013/SimpleTodo",
	},
	{
		title: "Flixster",
		subtitle: "Java, Android",
		description: "Simple movie app for Andoird. Created for Codepath project.",
		image: "./flixpt2.gif",
		link: "https://github.com/cfonseca2013/flixster",
	},
	{
		title: "MoodiFy",
		subtitle: "Swift, iOS",
		description:
			"MooDify is an iOS app that allows users to take a picture or upload and existing on from the library which is then analyzed to determine the user’s emotional state and then will play a song form Spotify tailored to the user’s mood. ",
		image: "./moodifyport.gif",
	},
	/*
  {
    title: "Epic Todo App",
    subtitle: "React Hooks",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium dolore rerum laborum iure enim sint nemo omnis voluptate exercitationem eius?",
    image: "./logo512.png",
    link: "https://reactbootcamp.com",
  },*/
];

export const skills = [
	"JavaScript",
	"React",
	"Next.js",
	"Node",
	"GraphQL",
	"Material UI",
];
